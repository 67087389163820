<template>
  <div class="bg-white">
    <div class=" content-wrapper px-3 py-2">
      <div class="d-flex align-items-center mb-2">
        <router-link class="pointer h5 m-0" :to="`/homologation/${$route.params.homologation_id}/service/${$route.params.service_id}/admin/validation`">
          <b-icon icon="arrow-left" class="mr-2" />
        </router-link>
        <skeleton
          v-if="!provider.social_reason"
          height="24px"
          :rows="1"
          grid="1"
        />
        <h5 class="m-0" v-else>
          {{ provider.social_reason }}
        </h5>
      </div>
      <div class="mb-4">
        Nome Fantasia: <strong>{{ provider.fantasy_name }}</strong>
      </div>
      <cards-list grid="3">
        <div>
          <div class="mb-2">
            CNPJ:
            <strong>{{ provider.cnpj | VMask("##.###.###/####-##") }}</strong>
          </div>
          <div class="mb-2">
            Situação Cadastral:
            <strong>{{ provider.active ? "Ativa" : "Baixada" }}</strong>
          </div>
        </div>
        <div>
          <div class="mb-2">
            Data de abertura:
            <strong>{{ provider.founded_at | date }}</strong>
          </div>
          <div>
            Natureza Jurídica: <strong>{{ provider.segment }}</strong>
          </div>
        </div>
        <div>
          <div class="mb-2">
            E-mail: <strong>{{ provider.email }}</strong>
          </div>
          <div>CEP: <strong>31620-460</strong></div>
        </div>
      </cards-list>
    </div>
    <hr class="m-0" />
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      service_id: "",
      provider_id: "",
      provider: {},
    };
  },

  methods: {
    ...mapActions(["get_provider", "select_provider_winner"]),
    getProvider() {
      this.get_provider({ id: this.provider_id }).then(({ data }) => {
        this.provider = data;
      });
    },
    handleSelectProvider() {
      this.loading = true;
      this.select_provider_winner({
        provider_id: this.provider_id,
        service_id: this.service_id,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => (this.loading = false))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getProvider();
  },
};
</script>
