<template>
  <div>
    <strong>Anexos</strong>
    <hr />

    <b-card
      no-body
      class="p-2 mb-2"
      style="border-radius: 0"
      v-for="(attachment, index) in attachments"
      :key="index"
    >
      <b-media class="d-flex align-items-center">
        <template #aside>
          <img :src="handleGetIcon(attachment)" width="30" />
        </template>

        <div class="d-flex justify-content-between w-100">
          <small class="mt-0 col-8 p-0">
            {{ attachment.file_name }}
          </small>
          <div class="d-flex icon-action">
            <b-icon
              icon="download"
              class="pointer"
              @click="handleDownloadURI(attachment)"
            />
          </div>
        </div>
      </b-media>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      attachments: [],
      service_id: "",
      selectedId: "",
    };
  },
  methods: {
    ...mapActions(["get_service_validation_attachments"]),

    handleDownloadURI({ file_name, file_type, url }) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${file_name}.${file_type}`;
          link.click();
        })
        .catch(console.error);
    },

    handleGetIcon(attachment) {
      const files = ["docx", "pdf", "txt", "xls"];
      if (files.includes(attachment.file_type)) {
        return require(`@/assets/images/icons/${attachment.file_type}.png`);
      }
      switch (true) {
        case ["jpg", "jpeg", "png", "gif"].includes(attachment.file_type):
          return require(`@/assets/images/icons/image.png`);
        case ["xls", "xlsx"].includes(attachment.file_type):
          return require(`@/assets/images/icons/xls.png`);
        case ["m4a", "mp3", "mp4", "wav", "wma", "aac"].includes(
          attachment.file_type
        ):
          return require(`@/assets/images/icons/audio.png`);
        default:
          return require(`@/assets/images/icons/file.png`);
      }
    },

    getAttachments() {
      this.get_service_validation_attachments({
        service_id: this.service_id,
      }).then(({ results }) => {
        this.attachments = results;
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;

    this.getAttachments();
  },
};
</script>
