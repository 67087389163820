<template>
  <div class="side-tab-validation d-flex gap-1">
    <b-card class="w-100">
      <div class="content-wrapper">
        <div>
          <service-detail v-show="selectedMenu.key === 'service'" />
          <invites v-show="selectedMenu.key === 'invites'" />
          <attachments v-show="selectedMenu.key === 'attachments'" />
          <conversation v-show="selectedMenu.key === 'conversation'" />
          <borrower v-show="selectedMenu.key === 'borrower'" />
        </div>
      </div>
    </b-card>
    <div class=" d-flex flex-column">
      <b-button
        size="sm"
        :variant="selectedMenu.key === menu.key ? 'primary' : 'light'"
        class="button-side-menu"
        v-for="(menu, index) in menus"
        :key="index"
        @click="handleSelectMenu(menu)"
      >
        <b-icon :icon="menu.icon" />
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Attachments from "./Menus/Attachments";
import ServiceDetail from "./Menus/ServiceDetail";
import Conversation from "./Menus/Conversation";
import Invites from "./Menus/Invites";
import Borrower from "./Menus/Borrower";

export default {
  components: { ServiceDetail, Attachments, Conversation, Invites, Borrower },
  data() {
    return {
      selectedMenu: {},
      menus: [
        {
          key: "service",
          icon: "card-list",
        },
        {
          key: "borrower",
          icon: "person-badge-fill",
        },
        {
          key: "invites",
          icon: "envelope",
        },
        {
          key: "attachments",
          icon: "paperclip",
        },
        {
          key: "conversation",
          icon: "chat",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile", "service_scope"]),
  },
  methods: {
    ...mapActions(["hide_application_header", "get_service_scope"]),
    handleSelectMenu(menu) {
      this.selectedMenu = menu;
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
    this.handleSelectMenu(this.menus[0]);
  },
};
</script>
<style lang="scss">
.side-tab-validation {
  width: 492px;

  .button-side-menu {
    height: 40px;
    width: 40px;
    padding: 0;
    font-size: 1em;
    margin-bottom: 1px;
  }
}
</style>
