<template>
  <b-card class="mb-3">
    <div class="w-100 ">
      <div class="d-flex justify-content-between mb-2">
        <strong class="d-block text-left mb-3">
          Pontuação dos fundamentos
        </strong>
      </div>
      <b-row>
        <b-col cols="6" v-for="(data, index) in fundamentals" :key="index">
          <span class="d-block text-center mb-1">{{ data.title }}</span>

          <div class="fundo text-center mt-2">
            <div id="chat-fundamentals" class="d-block mx-auto chart-data">
              <div :id="`gauge-validation-${index}`" />
              <vue-gauge :refid="`gauge-validation-${index}`" :options="getOptions(data)" />
              <cilinder-chat :total="data.total.grade" />

              <h5 class="mt-3 text-center">
                Peso: {{ data.total.weight * 100 }}%
              </h5>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table bordered :items="fundamentals" :fields="fields" small class="mt-4" />
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import VueGauge from "vue-gauge";
import CilinderChat from "@/components/CilinderChat";

export default {
  components: {
    VueGauge,
    CilinderChat,
  },
  props: {
    fundamentals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "title",
          label: "Indicador",
          sortable: false,
          colspan: 5,
        },
        {
          key: "total.weight",
          label: "Peso",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (item) => `${item * 100}%`,
        },
        {
          key: "total.grade",
          label: "Pontos",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "total.ponderation",
          label: "Ponderação",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (item) => `${item.toFixed(2)}`,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["is_mobile", "is_dark"]),
  },

  methods: {
    getOptions(data) {
      if (data.total) {
        const total = data.total;
        const value = total?.grade ? total.grade : 0;
        return {
          needleColor: this.is_dark ? "white" : "black",
          chartWidth: 250,
          needleStartValue: value,
          arcDelimiters: [20, 40, 60, 80],
          arcLabels: [2, 4, 6, 8],
          rangeLabel: ["0", "10"],
          hasNeedle: true,
          arcColors: ["#ff3300", "#f84438", "#e9c31b", "#b7ec82", "#589c14"],
          centralLabel: `${value}`,
          needleValue: value * 10,
        };
      }
    },
  },
};
</script>
