<template>
  <div class="p-0">
    <strong>Prestadores convidados</strong>
    <hr />
    <div
      v-for="(notification, index) in service_scope.notifications"
      :key="index"
      no-body
      :sub-title="notification.email"
    >
      <p class="mt-1">{{ notification.email }}</p>
      <p>Data do envio: <strong>20/10/22 13:00</strong></p>
      <p>
        Status:
        <strong v-if="notification.is_expired" class="text-danger">
          Expriado
        </strong>
        <strong v-else>Pendente</strong>
      </p>
      <hr class="my-2" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["service_scope"]),
  },
  data() {
    return {
      statusEmail: {
        1: "Pendente",
        2: "Aceito",
        3: "Cancelado",
        4: "Recusado",
      },
    };
  },
};
</script>
