const success = {
  history: [
    {
      id: 2,
      name: "Técnico Operacional",
      children: [
        {
          name: "Localização da base operacional",
          url: "location-base",
          status: 2,
        },
        {
          name: "Atividade econômica",
          url: "economic-activity",
          status: 3,
        },
        {
          name: "Porte da empresa",
          url: "company-size",
          status: 3,
        },
        {
          name: "Seguros de cobertura de riscos",
          url: "insurance-coverage",
          status: 3,
        },
        {
          name: "Questionário",
          url: "questionaire",
          status: 3,
        },
        {
          name: "Pesquisa de Satisfação",
          url: "technical-capacity",
          status: 3,
        },
      ],
      base: "operational-technician",
      progress: 83.33333333333333,
      description:
        "Consulta principais clientes; Análise Sistema de Informação; Tecnologias utilizadas; Plano de Continuidade do Negócio; Análise de Risco; Controles internos",
      icon: "technical-support",
      enabled: false,
    },
    {
      id: 3,
      name: "Legal Trabalhista",
      children: [
        {
          name: "Ações trabalhistas",
          url: "labor-lawsuits",
          status: 3,
        },
        {
          name: "Gestão de pessoas",
          url: "human-resources",
          status: 3,
        },
        {
          name: "Sistemas de gestão e informação",
          url: "system-management",
          status: 3,
        },
      ],
      base: "legal-labor",
      progress: 100,
      description:
        "Certidão Negativa de Execução Trabalhistas; Análise Certidão Distribuição Trabalhista; % de processo sobre efetivo operacional; Passivo Trabalhista total; Metodologia de Pagamentos; Horas Extras",
      icon: "lawyer",
      enabled: true,
    },
    {
      id: 4,
      name: "Regularidade",
      children: [
        {
          name: "Certificações",
          url: "certifications",
          status: 2,
        },
        {
          name: "Consulta de crédito",
          url: "credit-inquiry",
          status: 2,
        },
      ],
      base: "regularity",
      progress: 0,
      description:
        "Certidação Débitos; Certificações; Seguros; Alvarás e Autorizações Especiais; etc",
      icon: "note",
      enabled: true,
    },
    {
      id: 1,
      name: "Econômico Financeiro",
      children: [
        {
          name: "Lançamento de rendimentos",
          url: "balance-sheet",
          status: 3,
        },
        {
          name: "Indicadores financeiros",
          url: "income",
          status: 3,
        },
      ],
      base: "financial",
      progress: 100,
      description:
        "Foco na Análise de Líquidez de Curto, Envidamento Bancário e Fiscal, Cobertura de Provisões Férias e 13o. Salário,  Dependência Econômica, Situção Fiscal. Esse fatores são determinantes na análise e no resultado do nosso trabalho",
      icon: "budget",
      enabled: true,
    },
  ],
};

export { success };
