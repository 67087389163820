<template>
  <div>
    <component :is="layout" v-bind="props" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      indicatoModule: "",
      indicatoSubModule: "",
      props: {
        enabled: false,
      },
    };
  },
  computed: {
    layout() {
      const routesSnapshot = this.$router.options.routes[6]?.children[8]
        ?.children[7]?.children;

      const moduleRoute = routesSnapshot.find((route) =>
        route.path.split("/").includes(this.indicatoModule)
      )?.children;

      const subModuleComponent = moduleRoute.find((route) =>
        route.path.split("/").includes(this.indicatoSubModule)
      )?.component;

      return subModuleComponent;
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.indicatoModule = route.query.module;
        this.indicatoSubModule = route.query.sub;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
