<template>
  <div class="p-0">
    <strong>Tomador do serviço</strong>
    <hr />
    <div v-if="borrower">
      <div class="mb-2">
        <h6 class="m-0">
          {{ borrower.social_reason }}
        </h6>
      </div>
      <div class="mb-4">
        <small> {{ borrower.fantasy_name }}</small>
      </div>
      <div>
        <div>
          <div class="mb-2">
            CNPJ:
            <strong>
              {{ borrower.cnpj | VMask("##.###.###/####-##") }}
            </strong>
          </div>
          <div class="mb-2">Situação Cadastral: <strong>Ativa</strong></div>
        </div>
        <div>
          <div class="mb-2">
            Data de abertura:
            <strong>
              {{ borrower.founded_at | date }}
            </strong>
          </div>
          <div class="mb-2">Natureza Jurídica: <strong></strong></div>
        </div>
        <div class="mb-2">
          E-mail:
          <strong>
            {{ borrower.email }}
          </strong>
        </div>
        <div v-if="borrower && borrower.address">
          <hr />
          <strong class="mb-2 d-block">Endereço</strong>
          <p>
            Endereço:
            <strong>
              {{ borrower.address.place }}
            </strong>
          </p>
          <p>
            Cidade:
            <strong>
              {{ borrower.address.city }}
            </strong>
          </p>
          <p>
            Estado:
            <strong>
              {{ borrower.address.state }}
            </strong>
          </p>
          <p>
            CEP:
            <strong>
              {{ borrower.address.zip_code | VMask("##.###-###") }}
            </strong>
          </p>
        </div>
        <div v-if="borrower && borrower.default_responsible">
          <hr />
          <strong class="mb-2 d-block">Responsável</strong>

          <p>
            Nome: <strong> {{ borrower.default_responsible.name }}</strong>
          </p>
          <p>
            CPF:
            <strong>
              {{ borrower.default_responsible.cpf | VMask("###.###.###-##") }}
            </strong>
          </p>
          <p>
            E-mail: <strong>{{ borrower.default_responsible.email }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["service_scope"]),
  },

  data() {
    return {
      borrower: {},
    };
  },

  watch: {
    service_scope: {
      handler(service) {
        this.borrower = service?.homologation?.borrower;
      },
      immediate: true,
    },
  },
};
</script>
