<template>
  <div class="w-100">
    <div class="d-flex align-items-center justify-content-between gap-2  mb-3">
      <strong class="m-0">Validação do prestador</strong>
      <div class="d-flex items-center gap-2">
        <b-button
          @click="handleSelectType('fundamentals')"
          :variant="type === 'fundamentals' ? 'primary' : 'light'"
          size="sm"
        >
          Fundamentos
        </b-button>
        <b-button
          @click="handleSelectType('report')"
          :variant="type === 'report' ? 'primary' : 'light'"
          size="sm"
        >
          Relatório
        </b-button>
        <!-- <b-button
          @click="handleSelectType('costs')"
          :variant="type === 'costs' ? 'primary' : 'light'"
          size="sm"
        >
          Custos
        </b-button> -->
        <!-- <b-button
          @click="handleSelectType('ponderation')"
          :variant="type === 'ponderation' ? 'primary' : 'light'"
          size="sm"
        >
          Ponderação final
        </b-button> -->
      </div>
    </div>

    <fundamentals v-if="type === 'fundamentals'" />
    <report v-if="type === 'report'" />
    <costs v-if="type === 'costs'" />
    <final-ponderation v-if="type === 'ponderation'" />
  </div>
</template>

<script>
import Fundamentals from "./Fundamentals";
import Costs from "./Costs";
import FinalPonderation from "./FinalPonderation";
import Report from "./Result/index";

export default {
  components: {
    Fundamentals,
    Costs,
    FinalPonderation,
    Report
  },
  data() {
    return {
      type: "fundamentals",
    };
  },

  methods: {
    handleSelectType(type) {
      this.type = type;
    },
  },
};
</script>
