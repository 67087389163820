<template>
  <b-card class="min-h-65">
    <h6 class="mb-3">Ponderação final dos fundamentos</h6>
    <b-card>
      <div>
        <section class="d-flex align-items-center justify-content-between">
          <strong class="d-block">
            Economico Financeiro
          </strong>
          <b-icon
            :icon="ponderation_financial ? 'x' : 'pen-fill'"
            class="icon pointer"
            @click="ponderation_financial = !ponderation_financial"
          />
        </section>
        <div v-if="!ponderation_financial" v-html="content" class="mt-2" />
      </div>
      <b-collapse v-model="ponderation_financial" id="collapse-3" class="mt-3">
        <vue-editor
          v-model="content"
          ref="editor"
          :editorToolbar="customToolbar"
        />
      </b-collapse>
    </b-card>
  </b-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      ponderation_financial: true,
      content: "",
      service_id: "",
      provider_id: "",
      indicatoModule: "",
      indicatoSubModule: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  methods: {
    ...mapActions([
      "set_service_validation_admin_note",
      "get_service_validation_admin_note",
    ]),

    updateCommentNote: _.debounce(function(note) {
      if (!note) return;

      this.set_service_validation_admin_note({
        service_id: this.service_id,
        provider_id: this.provider_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
        note,
      });
    }, 1000),

    getNote() {
      this.get_service_validation_admin_note({
        service_id: this.service_id,
        provider_id: this.provider_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
      }).then(({ data }) => {
        this.content = data.note;
      });
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getNote();
    // this.$refs.editor.quill.focus();
  },
  watch: {
    content: {
      handler(key) {
        this.updateCommentNote(key);
      },
      immediate: true,
    },
  },
  $route: {
    handler(route) {
      this.indicatoModule = route.query.module;
      this.indicatoSubModule = route.query.sub;
    },
    immediate: true,
    deep: true,
  },
};
</script>
