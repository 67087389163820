<template>
  <div class="w-100 d-flex">
    <context-menu context_class="min-h-65">
      <template v-for="(history, index) in validation_admin_history">
        <b-button
          pills
          class="d-flex align-items-center text-nowrap justify-content-between gap-2 px-2 w-100 "
          @click="handleSelectModule(history)"
          :variant="$route.query.module === history.base ? 'primary' : 'light'"
          :key="index"
        >
          <span>{{ history.name }}</span>
          <b-badge
            variant="danger"
            class="rounded"
            v-if="history.need_attention"
          >
            <b-icon-exclamation-circle />
          </b-badge>
        </b-button>
      </template>
    </context-menu>
    <b-card class="min-h-65 w-100">
      <div class="d-flex align-items-center mb-4 flex-wrap h-auto gap-1">
        <b-button
          size="sm"
          class="px-2 d-flex align-items-center justify-content-between gap-2  line-height-1"
          @click="handleSelectChildrenModule(children)"
          :variant="$route.query.sub === children.url ? 'primary' : 'light'"
          v-for="(children, idx) in selectedModule.children"
          :key="idx"
        >
          <span class="text-left">{{ children.name }}</span>
          <b-badge
            variant="danger"
            class="rounded ml-2"
            v-if="children.need_attention"
          >
            <b-icon-exclamation-circle variant="danger" />
          </b-badge>
        </b-button>
      </div>
      <Indicator ref="option"/>
    </b-card>
  </div>
</template>

<script>
import { success } from "@/mocks/service-validation/history.js";
import Indicator from "./Indicator";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Indicator,
  },

  data() {
    return {
      show_submodule: false,
      provider_id: "",
      ranking_id: "",
      service_id: "",
      homologation_id: "",
      selectedModule: {},
      queryModule: "",
      querySubModule: "",
      selectedSubModule: {},
      modules: success.history,
    };
  },

  computed: {
    ...mapGetters(["service_scope", "validation_admin_history"]),
  },

  methods: {
    ...mapActions(["get_service_validation_admin_history"]),

    updateRoute() {
      const path = `/homologation/${this.homologation_id}/service/${this.service_id}/admin/validation/${this.provider_id}/${this.ranking_id}`;

      this.$router.push({
        path,
        query: {
          checkDuplicates: false,
          module: this.selectedModule.base,
          module_name: this.selectedModule.name,
          sub: this.selectedSubModule.url,
          sub_name: this.selectedSubModule.name,
        },
      });
    },

    handleSelectModule(mdl) {
      this.selectedModule = mdl;
      this.handleSelectChildrenModule(mdl.children[0]);
    },

    handleSelectChildrenModule(children) {
      this.selectedSubModule = children;
      this.$refs.option.handleSetType('indicators')
    },

    getServiceValidationAdminHistory() {
      this.get_service_validation_admin_history({
        caca: 'caca',
        ranking_id: this.ranking_id,
      }).then(({ data }) => {
        let mdl = data.history[0];

        if (this.queryModule) {
          mdl = data.history.find((item) => item.base === this.queryModule);
        }

        this.selectedModule = mdl;
      });
    },
  },

  mounted() {
    this.queryModule = this.$route.query.module;
    this.querySubModule = this.$route.query.sub;
    this.service_id = this.$route.params.service_id;
    this.homologation_id = this.$route.params.homologation_id;
    this.provider_id = this.$route.params.provider_id;
    this.ranking_id = this.$route.params.ranking_id;
    this.getServiceValidationAdminHistory();
  },

  watch: {
    selectedSubModule: {
      handler() {
        this.updateRoute();
      },
      deep: true,
    },
    validation_admin_history(value){
      this.handleSelectModule(value[0])  // Carrega a página com o primeiro módulo selecionado
    }
  },
};
</script>
