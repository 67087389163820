<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div>
        <span>Nota ponderada</span>
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="indicatoModule && indicatoSubModule"
        >
          <div class="d-flex align-items-center gap-2 w-100">
            <h5 class=" mb-0">{{ template.total_score || 0 }} pontos</h5>
          </div>
        </div>
      </div>
      <b-button-group>
        <b-button
          @click="handleSetType('indicators')"
          size="sm"
          :variant="type === 'indicators' ? 'secondary' : 'light'"
          class="text-nowrap p-0 px-3"
        >
          Indicadores
        </b-button>
        <b-button
          @click="handleSetType('snapshot')"
          size="sm"
          :variant="type === 'snapshot' ? 'secondary' : 'light'"
          class="text-nowrap p-0 px-3"
          target="_blank"
        >
          Snapshot
        </b-button>
        <b-button
          @click="handleSetType('ponderations')"
          size="sm"
          :variant="type === 'ponderations' ? 'secondary' : 'light'"
          class="text-nowrap p-0 px-3"
          target="_blank"
        >
          Comentários
        </b-button>
      </b-button-group>
    </div>
    <hr />
    <div v-if="busy" class="d-flex justify-content-center mb-3">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <list-indicators
      v-else-if="template && template.indicators && type === 'indicators'"
      :template="template"
      @update_indicators="getAllIndicators()"
    />
    <result-snapshot v-else-if="type === 'snapshot'" />
    <Ponderations v-else-if="type === 'ponderations'" />
  </div>
</template>

<script>
import ListIndicators from "./ListIndicators/";
import ResultSnapshot from "./ResultSnapshot/";
import Ponderations from "./Ponderations/";
import { mapActions } from "vuex";

export default {
  components: {
    ListIndicators,
    ResultSnapshot,
    Ponderations,
  },
  data() {
    return {
      template: {},
      service_id: "",
      ranking_id: "",
      indicatoModule: "",
      indicatoSubModule: "",
      type: "indicators",
      createdCalled: false,
      busy: false
    };
  },
  methods: {
    ...mapActions(["get_service_validation_admin_indicator"]),

    getAllIndicators() {
      this.busy = true
      this.get_service_validation_admin_indicator({
        ranking_id: this.ranking_id,
        service_id: this.service_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
        provider_id: this.provider_id,
      }).then(({ data }) => {
        this.template = data;
        this.busy = false
      });
    },

    handleSetType(type) {
      this.type = type;
    },
  },

  created() {
    this.service_id = this.$route.params.service_id;
    this.homologation_id = this.$route.params.homologation_id;
    this.provider_id = this.$route.params.provider_id;
    this.ranking_id = this.$route.params.ranking_id;
    this.createdCalled = true; // Initialize a flag
  },

  watch: {
    $route: {
      handler(route) {
        if (this.createdCalled){
          this.indicatoModule = route.query.module;
        this.indicatoSubModule = route.query.sub;
        this.getAllIndicators();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
