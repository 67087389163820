<template>
  <div>
    <span class="d-block mb-3">
      Comentários para - <strong>{{ indicatoModuleName }}</strong> -
      <strong>{{ indicatoSubModuleNme }}</strong>
    </span>
    <vue-editor v-model="content" ref="editor" :editorToolbar="customToolbar" />
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      content: "",
      service_id: "",
      provider_id: "",
      indicatoModule: "",
      indicatoModuleName: "",
      indicatoSubModule: "",
      indicatoSubModuleNme: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  methods: {
    ...mapActions([
      "set_service_validation_admin_note",
      "get_service_validation_admin_note",
    ]),

    updateCommentNote: _.debounce(function(note) {
      if (!note) return;

      this.set_service_validation_admin_note({
        service_id: this.service_id,
        provider_id: this.provider_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
        note,
      });
    }, 1000),

    getNote() {
      this.get_service_validation_admin_note({
        service_id: this.service_id,
        provider_id: this.provider_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
      }).then(({ data }) => {
        this.content = data.note;
      });
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getNote();
    // this.$refs.editor.quill.focus();
  },
  watch: {
    content: {
      handler(key) {
        this.updateCommentNote(key);
      },
      immediate: true,
    },
    $route: {
      handler(route) {
        this.indicatoModule = route.query.module;
        this.indicatoSubModule = route.query.sub;
        this.indicatoModuleName = route.query.module_name;
        this.indicatoSubModuleNme = route.query.sub_name;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
