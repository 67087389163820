<template>
  <b-card class="min-h-70">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h6>Custos</h6>
      <b-button @click="handlePushCost">
        Adicionar custo
      </b-button>
    </div>
    <empty-list
      text="Não há custos adicionados"
      v-if="!loading && costs.length === 0"
    />

    <b-list-group>
      <b-list-group-item
        v-for="(cost, index) in costs"
        :key="index"
        class="d-flex align-items-center gap-3 p-0 py-2"
      >
        <b-form-input
          debounce="500"
          autofocus
          size="sm"
          v-model="cost.name"
          type="text"
          @blur="handleChangeCost(cost, index)"
        />
        <div class="d-flex align-items-center gap-3">
          <b-form-input
            debounce="500"
            v-money.lazy="maskMoney"
            size="sm"
            v-model="cost.value"
            type="text"
            @blur="handleChangeCost(cost, index)"
          />
          <div class="actions d-flex align-items-center">
            <b-icon-trash
              variant="danger"
              class="pointer icon-action icon-delete"
              @click="handleDelete(index)"
            />
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      maskMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
      loading: false,
      loadingDelete: false,
      costs: [],
    };
  },
  methods: {
    ...mapActions([
      "get_service_validation_admin_costs",
      "update_service_validation_admin_costs",
      "set_service_validation_admin_costs",
      "delete_service_validation_admin_costs",
    ]),

    handlePushCost() {
      const length = this.costs.length;

      const lastCost = this.costs[length - 1];

      if (length === 0) {
        return this.costs.push({ name: "", value: "" });
      }

      if (!lastCost?.name.length) {
        return;
      }

      this.costs.push({ name: "", value: "" });
    },

    getAllCosts() {
      this.loading = true;
      this.get_service_validation_admin_costs({
        service_id: this.service_id,
        provider_id: this.provider_id,
      })
        .then(({ data, status }) => {
          if ([200, 201].includes(status)) {
            this.costs = data;
          }
        })
        .catch(() => {
          this.costs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addCost(cost) {
      const payload = {
        ...cost,
        service_id: this.service_id,
        provider_id: this.provider_id,
      };
      this.set_service_validation_admin_costs(payload);
    },

    editCost(cost) {
      const payload = {
        ...cost,
        service_id: this.service_id,
        provider_id: this.provider_id,
      };
      this.update_service_validation_admin_costs(payload);
    },

    async handleDelete(index) {
      const cost = this.costs[index];

      this.costs.splice(index, 1);

      if (cost?.id) {
        this.delete_service_validation_admin_costs(cost.id);
      }
    },

    handleChangeCost: _.debounce(function(cost) {
      if (cost?.name.length === 0) return;

      if (cost.id) {
        return this.editCost(cost);
      }
      return this.addCost(cost);
    }, 500),
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getAllCosts();
  },
};
</script>

<style lang="scss" scoped>
.icon-delete {
  width: 16px;
  height: 16px;
}
</style>
