<template>
  <div>
    <Provider />
    <div
      class="d-flex content-wrapper justify-content-between min-h-70 gap-3 mt-3 px-3"
    >
      <Content />
      <SideMenu />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SideMenu from "./SideMenu/";
import Provider from "./Provider";
import Content from "./Content";

export default {
  components: {
    SideMenu,
    Provider,
    Content,
  },

  data() {
    return {
      homologation_id: "",
      service_id: "",
      provider_id: "",
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["hide_application_header"]),
  },

  beforeDestroy() {
    this.hide_application_header(true);
  },

  mounted() {
    this.hide_application_header(false);
    this.homologation_id = this.$route.params.homologation_id;
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
  },
};
</script>
