<template>
  <div class="min-h-66">
    <strong>Atualizações</strong>
    <hr />
    <div class="d-flex flex-column justify-content-between">
      <div ref="conversations" class="comments">
        <div v-for="(comment, index) in conversations" :key="index">
          <section class="mr-md-4 my-2">
            <section
              class="
                d-md-flex
                align-items-start
                justify-content-start
                flex-md-row
              "
            >
              <b-alert
                variant="light"
                show
                class="w-100 mb-0 p-0"
                v-html="renderHtml(comment)"
              />
            </section>
            <hr class="w-50" />
          </section>
        </div>
      </div>
      <div class="mt-2">
        <div>
          <section
            class="
            mb-md-0
            w-100 
            text-right
            mr-2
          "
          >
            <b-form-textarea
              class="my-1 "
              v-model="message"
              :class="error && 'is-invalid'"
              label="title"
              placeholder="Digite seu comentário"
              ref="commentArea"
            >
            </b-form-textarea>
            <b-button variant="primary" size="sm" @click="handleSendMessage()">
              Enviar
            </b-button>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      message: "",
      error: false,
      service_id: "",
      conversations: [],
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters(["user", "is_mobile", "current_provider"]),
  },

  methods: {
    ...mapActions([
      "add_service_validation_conversation",
      "get_service_validation_conversation",
    ]),

    renderHtml(comment) {
      return `
      <div>
       <div class="d-flex align-items-start justify-content-between">
        <strong class="col-8 p-0 text-dark">
          ${
            comment.is_maat
              ? "Maat Admin"
              : this.current_provider?.social_reason
          }
        </strong>
        <span class="text-muted" style="font-size: 10px ;white-space: nowrap;">
           ${this.moment(String(comment.created_at)).format("DD/MM/YY HH:mm")}
        </span>
        </div>
       <div class="text-muted">
         <small>${comment.message}  </small>
       </div>
      </div>
      `;
    },

    scrollTo(scroll) {
      const conversation = this.$refs.conversations;
      if (scroll != conversation.clientHeight)
        conversation.scrollIntoView({ behavior: "smooth" });
      conversation.scrollTop = scroll;
    },

    getServiceValidationConversation(scrollToEnd) {
      const conversation = this.$refs.conversations;
      const previousScrollHeight = conversation.scrollHeight;
      this.get_service_validation_conversation({
        service_id: this.service_id,
        current_page: this.currentPage,
      }).then(({ results }) => {
        if (results) {
          if (this.currentPage !== 1) {
            this.conversations = [...results.reverse(), ...this.conversations];
            return;
          }
          this.conversations = results.reverse();
          return;
        }
        this.currentPage -= 1;
      });

      this.$nextTick(() => {
        if (scrollToEnd) this.scrollTo(conversation.scrollHeight);
        else this.scrollTo(conversation.scrollHeight - previousScrollHeight);
      });
    },

    handleSendMessage() {
      this.add_service_validation_conversation({
        service_id: this.service_id,
        message: this.message,
        created_at: new Date().toISOString(),
      }).then(({ status }) => {
        if (status === 201) {
          this.conversations.push({
            is_maat: this.user.is_admin,
            message: this.message,
          });
          this.currentPage = 1;
          this.getServiceValidationConversation(true);
        } else {
          this.error = true;
        }
        return;
      });
      this.message = "";
      this.$refs.commentArea.focus();
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.getServiceValidationConversation(true);
    const conversation = this.$refs.conversations;
    conversation.addEventListener("scroll", () => {
      if (conversation.scrollTop === 0) {
        this.currentPage += 1;
        this.getServiceValidationConversation(false);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.comments {
  height: 50vh;
  overflow: auto;
}
</style>
