<template>
  <b-card>
    <div class="mt-2 min-h-57">
      <b-button
        size="sm"
        class="mr-2"
        @click="handleSelectTable(table)"
        :variant="selectedTable.id === table.id ? 'primary' : 'outline-primary'"
        v-for="(table, index) in fundamentals"
        :key="index"
      >
        {{ table.title }}
      </b-button>
      <FundoTable
        v-if="selectedTable && selectedTable.title"
        hide_table
        :data="{
          indicators: selectedTable.indicators,
          total: selectedTable.total,
        }"
      />
    </div>
  </b-card>
</template>
<script>
import FundoTable from "./FundoTable";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FundoTable,
  },

  data() {
    return {
      total: {},
      selectedTable: {},
      indicators: [],
      fundamentals: [],
      provider_id: "",
      service_id: "",
      fields: [
        {
          key: "name",
          label: "Indicadores",
          sortable: true,
        },
        {
          key: "weight",
          label: "Peso",
          sortable: true,
        },
        {
          key: "result",
          label: "Indicador",
          sortable: true,
          formatter: (value) => value.toFixed(2),
        },
        {
          key: "score",
          label: "Pontos",
        },
        {
          key: "grade",
          label: "Ponderação",
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
  methods: {
    ...mapActions(["get_fundamentals"]),

    handleSelectTable(table) {
      this.selectedTable = table;

      if (table?.indicators && table.total) {
        this.indicators = table.indicators;
        this.total = table.total;
      }
    },

    getFundamentals() {
      this.get_fundamentals({
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(({ data }) => {
        this.fundamentals = data.map((item, id) => {
          return {
            ...item,
            id,
          };
        });
        this.handleSelectTable(this.fundamentals[0]);
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;

    this.getFundamentals();
  },
};
</script>
